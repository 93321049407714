function coloredFormula(formula, colorize) {
    if (!formula || !colorize) {
        return formula;
    }
    const colored = formula.replace(/(\-[^0][0-9x\.]+)/g, (m, n) => `<span class="text-danger">${n}</span>`);

    return <span dangerouslySetInnerHTML={{ __html: colored }} />;
}

function HostsTeamFundFormula({ formula, hostPenalty, game }) {
    if (formula && hostPenalty) {
        const penalty = `-${hostPenalty}`;
        if (formula.endsWith(penalty)) {
            return (
                <>
                    {coloredFormula(formula.substring(0, formula.length - penalty.length), game.is_cancelled)}
                    <span className="text-danger">{penalty}</span>
                </>
            );
        }
    }
    return coloredFormula(formula, game.is_cancelled);
}

export default function GameExtraInfoPoints({ game, className, showFormula }) {
    const profit = game.extra_info?.profit;
    const points = game.extra_info?.points;

    if (profit == null && points == null) {
        return null;
    }

    const coefficient = game.extra_info?.coefficient;
    const revenue = game.extra_info?.revenue;
    const revenueFormula = game.extra_info?.revenue_formula;
    const hostsTeamFundAmount = game.extra_info?.hosts_team_fund_amount;
    const hostsTeamFundFormula = game.extra_info?.hosts_team_fund_formula;

    return (
        <div className={"extra-info-points " + (className || "")}>
            {profit != null && <span className={profit >= 0 ? "text-success" : "text-danger"}>{profit} ₽</span>}
            {profit != null && points != null && <br />}
            {false && points != null && (
                <small className="text-muted">
                    {points}&nbsp;баллов
                    {coefficient != null && <> (x{coefficient})</>}
                </small>
            )}
            {showFormula && revenueFormula ? (
                <>
                    <small className="text-body text-center text-nowrap extra-info-points__formula">
                        Выручка {coloredFormula(revenueFormula, game.is_cancelled)}={revenue} ₽
                        {hostsTeamFundFormula && (
                            <>
                                <br />
                                Фонд{" "}
                                <HostsTeamFundFormula
                                    formula={hostsTeamFundFormula}
                                    hostPenalty={game.extra_info?.host_penalty}
                                    game={game}
                                />
                                ={hostsTeamFundAmount} ₽
                            </>
                        )}
                    </small>
                </>
            ) : (
                <>
                    {hostsTeamFundAmount != null && (
                        <small className="text-body">
                            <br />
                            Фонд&nbsp;{hostsTeamFundAmount}&nbsp;₽
                        </small>
                    )}
                </>
            )}
        </div>
    );
}
