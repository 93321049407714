import Nav from "react-bootstrap/Nav";

export default function GameTeamsSelector({ gameTeams, selectedGameTeamId, onSelectGameTeamId, className }) {
    if (!gameTeams) {
        return null;
    }

    return (
        <div className={className}>
            <Nav
                as="ul"
                className="nav nav-pills justify-content-center"
                activeKey={selectedGameTeamId}
                onSelect={onSelectGameTeamId}
            >
                {gameTeams.length > 0 && (
                    <Nav.Item as="li">
                        <Nav.Link eventKey={"all"} className="mb-1 me-2">
                            <span>Все</span>
                        </Nav.Link>
                    </Nav.Item>
                )}
                {gameTeams.map((team) => (
                    <Nav.Item as="li" key={team.id}>
                        <Nav.Link eventKey={team.id} className="mb-1">
                            <span>{team.name}</span>
                        </Nav.Link>
                    </Nav.Item>
                ))}
                <Nav.Item as="li">
                    <Nav.Link eventKey={0} className="mb-1 me-2">
                        <span>Без команды</span>
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                    <Nav.Link eventKey={"new"} className="mb-1 bg-primary text-white">
                        <span>Новая команда</span>
                    </Nav.Link>
                </Nav.Item>
            </Nav>
        </div>
    );
}
