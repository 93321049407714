import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

export default function HostGrade({ hostGrade, className, showLevel, levelOnly }) {
    if (!hostGrade) {
        return null;
    }

    if ((showLevel || levelOnly) && hostGrade.level) {
        const bgColor = hostGrade.level.bg_color || "#8c969a";
        const style = { color: "#fff", background: bgColor, width: "fit-content" };
        const infoBtnStyle = { color: bgColor, background: "#fff" };
        const divClassName = "host-level-pill rounded-pill small " + (className || "");

        const helpText = (hostGrade.level.help_text || "").trim();
        const helpPopover =
            helpText.length > 0 ? (
                <Popover id="popover-host-level" className="host-level-pill__popover">
                    <Popover.Body>{helpText}</Popover.Body>
                </Popover>
            ) : null;

        return (
            <div className={divClassName} style={style}>
                {hostGrade.level.name}
                {helpPopover && (
                    <OverlayTrigger trigger={["hover", "focus"]} placement="auto" overlay={helpPopover}>
                        <a tabIndex="0" className="info-button" style={infoBtnStyle} role="button">
                            ?
                        </a>
                    </OverlayTrigger>
                )}
            </div>
        );
    }

    if (levelOnly) {
        return null;
    }

    return <div className={className}>{hostGrade.name}</div>;
}
