export default function ParticipantCount({ game, className }) {
    return (
        <>
            {game.participant_list.length > 0 && (
                <div className={"schedule__participant-count " + (className ? className : "")}>
                    {game.participant_list.length}
                    {game.max_participants ? `/${game.max_participants}` : ""}
                </div>
            )}
        </>
    );
}
