export default function GameCharacteristics({ game, className }) {
    const ratingClassName =
        game.is_friendly_peer_rating === true
            ? "text-success"
            : game.is_friendly_peer_rating === false
            ? "text-danger"
            : null;
    return (
        <div className={className}>
            {game.min_peer_rating ? (
                <>
                    <span className={ratingClassName}>Рейтинг от {game.min_peer_rating}</span>
                    {game.characteristics && <>, {game.characteristics.toLowerCase()}</>}
                </>
            ) : (
                game.characteristics
            )}
        </div>
    );
}
