export function getGameTeamFromList(gameTeams, selectedGameTeamId) {
    if (selectedGameTeamId == "new") {
        return {
            id: "new",
            name: "Новая команда",
        };
    }
    if (selectedGameTeamId == 0 || selectedGameTeamId == "all") {
        return {
            id: 0,
            name: "Без команды",
        };
    }
    return (
        gameTeams.find((team) => team.id == selectedGameTeamId) || {
            id: 0,
            name: "Без команды",
        }
    );
}

export function filterParticipantListByTeamId(participantList, gameTeamId) {
    if (gameTeamId == null || !/^[0-9]+$/.test(gameTeamId)) {
        return participantList;
    }

    const teamId = parseInt(gameTeamId);

    return participantList.filter((participant) => {
        if (teamId == 0 && !participant.game_team) {
            return true;
        }
        return teamId == participant.game_team?.id;
    });
}
