export default function ScenarioRating({ scenario,  asBlock }) {
    if (!scenario) {
        return <></>;
    }
    return (
        <>
            {(scenario.rating_num > 0) ? (
                <span className={"player-rating" + (asBlock ? " player-rating--block" : "")}>
                    {scenario.rating_avg}{" "}
                    <span className="player-rating__num-rates">
                        (
                        <span className="player-rating__num-rates--num">
                            {scenario.rating_num}
                        </span>
                        )
                    </span>
                </span>
            ) : (
                ""
            )}
        </>
    );
}

