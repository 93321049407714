import { useCity } from "../lib/context/citycontext";

export default function PlayerClan({ player, className }) {
    const { city } = useCity();
    if (!player?.clan) {
        return null;
    }
    return (
        <a href={`/${city.slug}/clans/${player.clan.id}/`} className={className}>
            {player.clan.name}
        </a>
    );
}
