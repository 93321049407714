import Nav from "react-bootstrap/Nav";

export default function MafiaGameSelector({
    mafiaGames,
    selectedMafiaGame,
    withTotal,
    onSelect,
    className,
    appendComponent,
}) {
    if (!mafiaGames || mafiaGames.length == 0) {
        return null;
    }

    return (
        <div className={className}>
            <Nav
                as="ul"
                className="nav nav-pills justify-content-center"
                activeKey={selectedMafiaGame}
                onSelect={onSelect}
            >
                {mafiaGames.map((number) => (
                    <Nav.Item as="li" key={number}>
                        <Nav.Link eventKey={number}>
                            <span>Раунд {number}</span>
                        </Nav.Link>
                    </Nav.Item>
                ))}
                {withTotal && (
                    <Nav.Item as="li">
                        <Nav.Link eventKey={0}>
                            <span>ИТОГО</span>
                        </Nav.Link>
                    </Nav.Item>
                )}
                {appendComponent && <Nav.Item as="li">{appendComponent}</Nav.Item>}
            </Nav>
        </div>
    );
}
