export default function ConversionInfo({ conversionInfo, className }) {
    if (!conversionInfo) {
        return null;
    }
    if (conversionInfo.is_excluded_from_calc) {
        return <div className={className || "mt-2"}>Не учитывается в Возвращаемость</div>;
    }
    return (
        <div className={className || "mt-2"}>
            {conversionInfo.conversion_calc_date
                ? `Будет учтена ${conversionInfo.conversion_calc_date}`
                : "Учитывается в Возвращаемость"}
            : {conversionInfo.new_players} - новых
            {conversionInfo.new_players ? `, ${conversionInfo.returned_players} - вернулись` : null}
        </div>
    );
}
