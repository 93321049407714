import React from "react";

import Popover from "react-bootstrap/Popover";

const PriceInfoPopover = React.forwardRef(({ game, ...props }, ref) => {
    let priceDescription = game.game_price_description;

    if (!priceDescription) {
        priceDescription = `${game.game_price_amount} ₽`;
    }

    return (
        <Popover ref={ref} {...props}>
            <Popover.Body>
                <div className="schedule__price-popover" dangerouslySetInnerHTML={{ __html: priceDescription }} />
            </Popover.Body>
        </Popover>
    );
});

PriceInfoPopover.displayName = "PriceInfoPopover";

export default PriceInfoPopover;
