import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

export default function RequestSuccessModal({ onHide }) {
    return (
        <Modal show centered className="modal--fancy modal--enrollment" onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title as="h5">Заказ игры</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="mb-4">
                    <h4>Заявка успешно отправлена.</h4>
                    <h5>Наш администратор свяжется с Вами.</h5>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={onHide}>
                    Закрыть
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
