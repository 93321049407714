export default function PlacesLeftInfo({ game, className }) {
    if (!game.places_left_info) {
        return null;
    }

    const { places, label, critical, players_waiting } = game.places_left_info;

    const placesClassName = "places-left-info__places" + (critical ? " places-left-info__places--critical" : "");
    const playersWaiting = players_waiting && players_waiting > 0 && !places ? players_waiting : null;

    return (
        <div className={`places-left-info ${className}`}>
            Осталось <span className={placesClassName}>{places}</span> {label}
            {playersWaiting && (
                <>
                    , Лист ожидания <span className="text-primary"><b>{playersWaiting}</b></span> чел.
                </>
            )}
        </div>
    );
}
