import { isMeet4USite } from "../lib/coreutils";

export default function WinnerExperienceGain({ game, className }) {
    if (isMeet4USite()) {
        return null;
    }

    if (game.is_mafia_game) {
        if (game.mafia_points_per_score) {
            return (
                <div className={className}>
                    Вес 1 балла = <b>{game.mafia_points_per_score}</b> очков
                </div>
            );
        }
        return null;
    }
    if (game.experience_gained_by_winner) {
        return (
            <div className={className}>
                Победитель получит <b>{game.experience_gained_by_winner}</b> очков
            </div>
        );
    }

    if (
        game.participant_list.length > 0 &&
        game.participant_list[0].place == 1 &&
        parseFloat(game.participant_list[0].experience_gained)
    ) {
        return (
            <div className={className}>
                Победитель получил <b>{game.participant_list[0].experience_gained}</b> очков
            </div>
        );
    }

    return null;
}
