export default function PlayerPeerRating({ player, ratingsUrl, noDecoration, className, asBlock }) {
    if (!player || !player.peer_rating_avg) {
        return null;
    }
    return (
        <>
            {player.peer_rating_num ? (
                <span
                    className={
                        "player-rating" +
                        (asBlock ? " player-rating--block" : "") +
                        (noDecoration ? " player-rating--no-decoration" : "") +
                        (className ? " " + className : "")
                    }
                >
                    {player.peer_rating_avg}
                    <span className="player-rating__num-rates">
                        (
                        <span className="player-rating__num-rates--num">
                            {ratingsUrl ? <a href={ratingsUrl}>{player.peer_rating_num}</a> : player.peer_rating_num}
                        </span>
                        )
                    </span>
                </span>
            ) : (
                ""
            )}
        </>
    );
}
