import { callAPIGet } from "../api";

export async function fetchMafiaGame(id, number) {
    const response = await callAPIGet(`/api/v1/mafia/${id}/`, { number });
    return response.json();
}

export async function fetchMafiaRatingPageProps(city, page, role, order_by, cookies) {
    const response = await callAPIGet("/api/v1/pages/ratingmafia/", { city, page, role, order_by }, { cookies });
    return response.json();
}

export async function fetchMafiaGamePageProps(city, game, number, cookies) {
    return callAPIGet("/api/v1/pages/mafia/", { city, game, number }, { cookies });
}
